import {
  useEffect,
  useState,
} from "react"
import { useDaySelector } from "../useDaySelector"
import {
  useDeviceSettingsProperty,
  useEventsAndSubEntriesOfType,
} from "src/hooks"
import type {
  UserItemSharedProperties,
  GlucoseSubEntry,
  GlucoseUserEvent,
} from "src/types"
import { ActivityType } from "src/types"

interface ReturnValue {
  percentageAboveNormal: number;
  percentageWithinNormal: number;
  percentageBelowNormal: number;
}

export function useNormalRangeDayMetric(dayStart: string): ReturnValue | undefined {
  const normalRangeTop = useDeviceSettingsProperty("range_normal_top")
  const normalRangeBottom = useDeviceSettingsProperty("range_normal_bottom")

  const daySelector = useDaySelector(dayStart)

  const [
    normalRangeDay,
    setNormalRangeDay,
  ] = useState<ReturnValue | undefined>()

  const {
    isFetchingEvents,
    isFetchingEntries,
    events: glucoseEvents,
    subEntries: glucoseSubEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Glucose,
    daySelector,
  )

  useEffect(
    () => {
      if (isFetchingEntries || isFetchingEvents) {
        return
      }

      const totalReadings = glucoseEvents.length + glucoseSubEntries.length

      if (totalReadings === 0) {
        setNormalRangeDay(undefined)
        return
      }

      if (normalRangeTop === undefined || normalRangeBottom === undefined) {
        return
      }

      let numberBelowNormal = 0
      let numberWithinNormal = 0
      let numberAboveNormal = 0

      const calculate = (item: GlucoseUserEvent | (GlucoseSubEntry & UserItemSharedProperties)) => {
        if (item.glucose.mg_dl < normalRangeBottom) {
          numberBelowNormal += 1
        } else if (item.glucose.mg_dl > normalRangeTop) {
          numberAboveNormal += 1
        } else {
          numberWithinNormal += 1
        }
      }

      glucoseEvents.forEach(calculate)
      glucoseSubEntries.forEach(calculate)

      setNormalRangeDay({
        percentageAboveNormal: numberAboveNormal / totalReadings,
        percentageWithinNormal: numberWithinNormal / totalReadings,
        percentageBelowNormal: numberBelowNormal / totalReadings,
      })
    },
    [
      isFetchingEntries,
      isFetchingEvents,
      setNormalRangeDay,
      glucoseEvents,
      glucoseSubEntries,
      normalRangeTop,
      normalRangeBottom,
    ],
  )

  return normalRangeDay
}
