import { NewRelicErrorTag } from "src/types"

interface NewRelicAgent {
  noticeError: (typeof newrelic)["noticeError"];
}

const isDevBuild = process.env?.NODE_ENV === "development"

export function noticeError(
  e: unknown,
  tagKey: keyof typeof NewRelicErrorTag,
  customAttributes: Record<string, unknown> = {},
  newRelicAgent: NewRelicAgent = newrelic,
) {
  const tag = NewRelicErrorTag[tagKey]

  if (e instanceof Error || typeof e === "string") {
    newRelicAgent.noticeError(
      e,
      {
        ...customAttributes,
        tag,
      },
    )

    if (isDevBuild) {
      console.warn(e)
    }
  } else {
    newRelicAgent.noticeError(
      tag,
      {
        ...customAttributes,
        tag,
      },
    )

    if (isDevBuild) {
      console.warn(tag)
    }
  }
}
