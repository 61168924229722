import { transformAccountMedicationsResponse } from "./transformAccountMedicationsResponse"
import {
  useRxDBSimpleLoader,
  useLoadRxDBCollection,
} from "src/hooks/rxdb"
import { accountMedicationCollectionKey } from "src/schema"

export function useLoadAccountMedicationsCollection() {
  const [
    ,
    loader,
    resetLoader,
  ] = useRxDBSimpleLoader(
    "account_meds",
    "/api/v3/account_meds",
    transformAccountMedicationsResponse,
  )

  useLoadRxDBCollection(
    accountMedicationCollectionKey,
    loader,
    resetLoader,
  )
}
