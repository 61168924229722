import {
  ActionSubmit,
  Form,
  LargeInput,
  useToasterContext,
  ValidationContainer,
  ValidationMessage,
} from "@tandemdiabetes/sugarmate-react-components"
import { Link } from "@tanstack/react-router"
import type {
  FormEventHandler,
  ChangeEventHandler,
} from "react"
import {
  useState,
  useCallback,
} from "react"
import styles from "./SignIn.module.scss"
import { PublicPageHeader } from "src/components"
import {
  usePolyglot,
  useAuthContext,
} from "src/contexts"
import { SignInErrorToast } from "src/toasts"

const emailId = "user-email-input"
const passwordId = "user-password-input"
const forgotPasswordId = "forgotPasswordButton"
const polyglotPrefix = "pages.sign_in."

const emailErrorId = "user-email-input-error"
const passwordErrorId = "user-password-input-error"

// TODO show "password criteria not met" error
export function SignIn() {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const { signIn } = useAuthContext()

  const [
    formState,
    setFormState,
  ] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  })

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (
        async () => {
          try {
            await signIn(
              formState.email,
              formState.password,
            )
          } catch (e) {
            showToast(
              "sign-in-error",
              SignInErrorToast,
            )
          }
        }
      )()
    },
    [
      formState.email,
      formState.password,
      showToast,
    ],
  )

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        email: e.target.value,
      })
    },
    [
      setFormState,
      formState,
    ],
  )

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        password: e.target.value,
      })
    },
    [
      setFormState,
      formState,
    ],
  )

  return (
    <div className={styles.container}>
      <PublicPageHeader backRoutePath="/">
        {polyglot.t(`${polyglotPrefix}title`)}
      </PublicPageHeader>
      <Form
        className={styles.form}
        onSubmit={onSubmit}
      >
        <ValidationContainer>
          <LargeInput
            id={emailId}
            placeholder={polyglot.t(`${polyglotPrefix}email.placeholder`)}
            type="email"
            className={styles.input}
            value={formState.email}
            onChange={onEmailChange}
            autoComplete="email"
            required
          />
          <ValidationMessage
            id={emailErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              typeMismatch: polyglot.t("validation_message.email_type_mismatch"),
            }}
          />
        </ValidationContainer>
        <ValidationContainer>
          <LargeInput
            id={passwordId}
            placeholder={polyglot.t(`${polyglotPrefix}password.placeholder`)}
            type="password"
            className={styles.input}
            value={formState.password}
            onChange={onPasswordChange}
            autoComplete="current-password"
            required
          />
          <ValidationMessage
            id={passwordErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
            }}
          />
        </ValidationContainer>
        <Link to="/forgot_password"
          className={styles.link}
          id={forgotPasswordId}
        >
          {polyglot.t(`${polyglotPrefix}forgot_password_link`)}
        </Link>
        <ActionSubmit
          value={polyglot.t(`${polyglotPrefix}submit_cta`)}
          stylePreset="primary"
          className={styles.submit}
        />
      </Form>
    </div>
  )
}
