import { useMemo } from "react"
import { useGlucoseValueAsPercentage } from "../hooks"
import styles from "./YAxisLabel.module.scss"
import { useReadableGlucoseValue } from "src/hooks"

interface Props {
  value: number;
}

/*
 * the text that labels the glucose value for a particular height along the Y axis
 */
export function YAxisLabel(props: Props) {
  const { value } = props
  const [bottom] = useGlucoseValueAsPercentage(value)
  const valueLabel = useReadableGlucoseValue(value)

  const containerStyle = useMemo(
    () => ({ bottom: `${bottom}%` }),
    [bottom],
  )

  return (
    <div
      className={styles.container}
      style={containerStyle}
      id="y-axis-ranges"
    >
      <div className={styles.label}>
        {valueLabel}
      </div>
    </div>
  )
}
