export enum NewRelicErrorTag {
  // swr
  SWRLocalStorageParse = "swr-local-storage-parse",
  SWRLocalStorageStringify = "swr-local-storage-stringify",

  // source
  CreateSource = "create-source",
  CreateDexcomSource = "create-dexcom-source",
  DeleteSource = "delete-source",
  UpdateSource = "update-source",

  // rxdb
  RxDBCreateDatabase = "rxdb-create-database",
  RxDBCreateDatabaseWorker = "rxdb-create-database-worker",
  RxDBRemoveDatabase = "rxdb-remove-database",
  RxDBCreateCollectionWorker = "rxdb-create-collection-worker",
  RxDBCreateCollection = "rxdb-create-collection",
  RxDBLoadCollection = "rxdb-load-collection",
  RxDBUpsertCollectionItem = "upsert-collection-item",
  RxDBWebsocketUpsert = "rxdb-websocket-upsert",
  RxDBWebsocketBulkUpsert = "rxdb-websocket-bulk-upsert",

  // crud operations for items stored in rxdb
  DeleteCollectionItem = "delete-collection-item",
  CreateCollectionItem = "create-collection-item",
  UpdateCollectionItem = "update-collection-item",

  // miscellanseou
  WebsocketRejected = "websocket-rejected",
  SaveSettings = "save-settings",
  CreatePolyglot = "create-polyglot",
  SignOut = "sign-out",
  AuthCookieParse = "auth-cookie-parse",
  MeResponseParse = "me-response-parse",
  ImageFileURL = "image-file-url",
  DuplicateSubEntry = "duplicate-sub-entry",
  DuplicateEntry = "duplicate-entry",
  CreateImage = "create-image",
  SearchFood = "search-food",
  SearchFoodImage = "search-food-image",
  GetDexcomRedirectURL = "get-dexcom-redirect-url",
  ChangePassword = "change-password",
  DeleteAccount = "delete-account",
  ForgotPasswordEmail = "forgot-password-email",
  ForgotPasswordRecaptcha = "forgot-password-recaptcha",
  CreateAccount = "create-account",
  CreatePhone = "create-phone",
  SignIn = "sign-in-new-account",
  OauthAuthorize = "oauth-authorize",
  ResetPassword = "reset-password",
  CreateReport = "create-report",
  InsightWorker = "insight-worker",
  TransformResponse = "transform-response",
  ReloadEvents = "reload-events",
}
