import classnames from "classnames"
import type { CSSProperties } from "react"
import {
  useCallback,
  useMemo,
  useRef,
} from "react"

import type { PartialGlucoseReadingProps } from "../Cell/PartialGlucoseReadingProps"
import type {
  PartialGraphItemProps,
  PartialActivityProps,
  PartialFavoriteProps,
} from "../Cell/PartialGraphItemProps"
import {
  ActivityGraphItem,
  FavoriteGraphItem,
} from "../GraphItem"
import styles from "./GraphItems.module.scss"
import { useAvailableSpaceAnalysis } from "./useAvailableSpaceAnalysis"
import { useSortedGraphItems } from "./useSortedGraphItems"

interface Props {
  subCellStartTime: string;
  subCellStopTime: string;
  glucoseReadings: {
    first?: PartialGlucoseReadingProps;
    last?: PartialGlucoseReadingProps;
  };
  graphItems: Array<PartialGraphItemProps>;
}

/*
 * this component organizes the graph items into the space above and below the glucose readings
 * it prevents the collision of neighboring graph items
 *
 * a "subCell" is a subdivision of a cell
 */
export function GraphItems(props: Props) {
  const {
    graphItems,
    glucoseReadings,
    subCellStartTime,
    subCellStopTime,
  } = props

  const containerRef = useRef<HTMLDivElement>(null)

  const {
    rising,
    rowsAboveGlucose,
    glucoseRows,
    rowsBelowGlucose,
    rowHeightPercentage,
  } =
    useAvailableSpaceAnalysis(
      subCellStopTime,
      glucoseReadings.first,
      glucoseReadings.last,
    )

  const [
    topGraphItems,
    bottomGraphItems,
  ] = useSortedGraphItems(
    graphItems,
    rowsAboveGlucose,
    rowsBelowGlucose,
  )

  const topStyle: CSSProperties = useMemo(
    () => ({
      height: `${rowsAboveGlucose * rowHeightPercentage}%`,
    }),
    [
      rowsAboveGlucose,
      rowHeightPercentage,
    ],
  )

  const topClasses = useMemo(
    () => classnames(
      styles.top,
      rising ? styles.rising : styles.falling,
    ),
    [
      rising,
      rowsAboveGlucose,
    ],
  )

  const bottomStyle: CSSProperties = useMemo(
    () => ({
      height: `${rowsBelowGlucose * rowHeightPercentage}%`,
    }),
    [
      rowsBelowGlucose,
      rowHeightPercentage,
    ],
  )

  const bottomClasses = useMemo(
    () => classnames(
      styles.bottom,
      rising ? styles.rising : styles.falling,
    ),
    [
      rising,
      rowsBelowGlucose,
    ],
  )

  const glucoseSpacerStyle: CSSProperties = useMemo(
    () => ({
      height: `${glucoseRows * rowHeightPercentage}%`,
    }),
    [
      glucoseRows,
      rowHeightPercentage,
    ],
  )

  const renderGraphItems = useCallback(
    (graphItemsProps: Array<PartialGraphItemProps>) => graphItemsProps.map(
      (itemProps) => {
        const {
          itemType,
          ...componentProps
        } = itemProps

        if (itemType === "activity") {
          return (
            <ActivityGraphItem
              {...componentProps as PartialActivityProps}
              key={componentProps.key}
              subCellStartTime={subCellStartTime}
              subCellStopTime={subCellStopTime}
            />
          )
        }
        return (
          <FavoriteGraphItem
            {...componentProps as PartialFavoriteProps}
            key={componentProps.key}
            subCellStartTime={subCellStartTime}
            subCellStopTime={subCellStopTime}
          />
        )
      },
    ),
    [
      subCellStartTime,
      subCellStopTime,
    ],
  )

  return (
    <div
      ref={containerRef}
      className={styles.container}
      id="graph-container"
    >
      <div className={topClasses}
        id="top-graph-range"
        style={topStyle}
      >
        {renderGraphItems(topGraphItems)}
      </div>
      <div
        id="glucose-spacing-cell"
        className={styles.glucoseSpacer}
        style={glucoseSpacerStyle}
      />
      <div className={bottomClasses}
        style={bottomStyle}
        id="bottom-graph-range"
      >
        {renderGraphItems(bottomGraphItems)}
      </div>
    </div>
  )
}
