import {
  createContext,
  useContext,
} from "react"

export type DbContextValue = {
  isLoadingLeader: boolean;
}

type ContextType = DbContextValue

export const DbContext = createContext<ContextType>({
  isLoadingLeader: false,
})

export function useDbContext(): DbContextValue {
  return useContext(DbContext)
}
