import {
  useRef,
  useLayoutEffect,
} from "react"
import type { MutableRefObject } from "react"

/*
 * this hook returns a ref that tracks the state of "value"
 */
export function useRefValue<V>(value: V): MutableRefObject<V> {
  const savedValue = useRef<V>(value)

  useLayoutEffect(
    () => {
      savedValue.current = value
    },
    [value],
  )

  return savedValue
}
