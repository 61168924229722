import type { AxiosRequestConfig } from "axios"
import dayjs from "dayjs"
import {
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react"
import type { RxCollection } from "rxdb"
import type {
  Loader,
  ResetLoader,
} from "./useLoadRxDBCollection"
import { useUserGet } from "src/hooks/userApi"
import { useRefValue } from "src/hooks/utils"
import type { ApiResponse } from "src/types"
import {
  mergeAxiosRequestConfigs,
  getOldestAndNewest,
  getOldestDate,
} from "src/utils"

interface Item {
  updated_at: string;
}

interface LoadingState {
  loaded: boolean;
  loading: boolean;
  loadedUntil: string | null;
}

type RequestItemsReturnValue = Array<Promise<string>>

/*
 * provides a progressive loader for useLoadRxDBCollection
 */
export function useRxDBProgressiveLoader<T extends Item, K extends string>(
  responseKey: K,
  url: string,
  oldestQueryTime: string,
): [LoadingState, Loader<T>, ResetLoader] {
  const userGet = useUserGet()
  const userGetRef = useRefValue(userGet)
  const canRequestItems = !!userGet

  const [
    loadingState,
    setLoadingState,
  ] = useState<LoadingState>({
    loaded: false,
    loading: false,
    loadedUntil: null,
  })

  const loadingStateRef = useRef<LoadingState>(loadingState)

  // this method updates the loading state by merging an updates with the
  // current loading state
  const updateLoadingState = useCallback(
    (newLoadingState: Partial<LoadingState>) => {
      const currentLoadingState = loadingStateRef.current

      const loadedUntil = (
        newLoadingState.loadedUntil &&
        dayjs(currentLoadingState.loadedUntil).isAfter(newLoadingState.loadedUntil)
      )
        ? currentLoadingState.loadedUntil
        : currentLoadingState.loadedUntil

      loadingStateRef.current = {
        ...currentLoadingState,
        ...newLoadingState,
        loadedUntil,
      }

      setLoadingState(loadingStateRef.current)
    },
    [
      loadingStateRef,
      setLoadingState,
    ],
  )

  const resetLoader = useCallback(
    () => {
      updateLoadingState({
        loaded: false,
        loading: false,
        loadedUntil: null,
      })
    },
    [updateLoadingState],
  )

  // the "loader" uses the userGet function to load all of the data for
  // the collection
  const loader = useMemo(
    () => !canRequestItems ? undefined : (
      async (collection: RxCollection<T>) => {
        // update the loading state to reflect that we have started loading items
        updateLoadingState({
          loaded: false,
          loading: true,
          loadedUntil: null,
        })

        const now = dayjs().toISOString()
        let insertionPromises: RequestItemsReturnValue = []

        // this function requests every page and adds items to the collection
        // until there are no more results
        const requestItems = async (
          requestConfig: AxiosRequestConfig,
          updateLoadedUntil: boolean,
        ): Promise<RequestItemsReturnValue> => {
          const promises: Array<Promise<string>> = []
          let page: number | null = 0

          while (page !== null) {
            // if the userGet function was invalidated stop loading
            if (!userGetRef.current) {
              resetLoader()

              break
            }

            const params: Record<string, number> = page ? { page } : {}

            // request the next batch of items
            const response = await userGetRef.current<ApiResponse<K, Array<T>>>(
              mergeAxiosRequestConfigs(
                {
                  url,
                  params,
                },
                requestConfig,
              ),
            )

            page = response.data.meta.next_page

            // throws an error if the collection was destroyed while loading
            // TODO collection.destroyed check should be safe to remove
            if (collection.destroyed) {
              throw new Error("collection destroyed")
            }

            // if loading was canceled while awaiting the request then break
            if (!loadingStateRef.current.loading) {
              break
            }

            // a promise that resolves when the items have been added to the collection
            const promise = (async () => {
              const items = response.data[responseKey]
              const lastItem = items[items.length - 1]

              // if loading was canceled while awaiting the query then return
              if (!loadingStateRef.current.loading) {
                return ""
              }

              // TODO try catch / notice
              await collection.bulkUpsert(items)

              // update the loading state
              // TODO collection.destroyed check should be safe to remove
              if (!collection.destroyed && lastItem && updateLoadedUntil) {
                updateLoadingState({ loadedUntil: lastItem.updated_at })
              }

              return lastItem?.updated_at ?? ""
            })()

            promises.push(promise)
          }

          return promises
        }

        try {
          // get the oldest and newest items from the collection
          // we don't need to request items in the time range covered by the collection
          const [
            oldestCollectionItemTime,
            newestCollectionItemTime,
          ] = await getOldestAndNewest(collection)

          // if loading was canceled while awaiting the query then return
          if (!loadingStateRef.current.loading) {
            return
          }

          if (newestCollectionItemTime && oldestCollectionItemTime) {
            const [
              newInsertionPromises,
              oldInsertionPromises,
            ] = await Promise.all([
              // request items after the newest item in the collection
              requestItems(
                {
                  params: {
                    before: now,
                    after: newestCollectionItemTime,
                  },
                },
                true,
              ),
              // request items before the oldest item in the collection
              requestItems(
                {
                  params: {
                    after: oldestQueryTime,
                    before: oldestCollectionItemTime,
                  },
                },
                false,
              ),
            ])

            insertionPromises = [
              ...newInsertionPromises,
              ...oldInsertionPromises,
            ]
          } else {
            // request items between now and the oldest query time
            insertionPromises = await requestItems(
              {
                params: {
                  before: now,
                  after: oldestQueryTime,
                },
              },
              true,
            )
          }

          // the oldest dates from each batch of items added to the collection
          const loadedUntilTimes: string[] = await Promise.all(insertionPromises)

          // the oldest date from all of the items that were added to the collection
          const loadedUntil = getOldestDate(
            newestCollectionItemTime ?? "",
            oldestCollectionItemTime ?? "",
            ...loadedUntilTimes,
          )

          updateLoadingState({
            loaded: true,
            loading: false,
            loadedUntil,
          })
        } catch (e) {
          updateLoadingState({
            loaded: true,
            loading: false,
            loadedUntil: null,
          })

          throw e
        }
      }
    ),
    [
      url,
      responseKey,
      userGetRef,
      canRequestItems,
      loadingStateRef,
      updateLoadingState,
    ],
  )

  return [
    loadingState,
    loader,
    resetLoader,
  ]
}
