import type { AxiosResponse } from "axios"
import { useCallback } from "react"

import { useSources } from "./useSources"
import { useMe } from "src/hooks/me"
import { useUserPost } from "src/hooks/userApi"
import { useReloadEvents } from "src/hooks/userEvent"
import type { Source } from "src/types"
import { noticeError } from "src/utils"

interface Response {
  status: string;
  already_exists?: boolean;
  last_point_at?: string;
}

type CreateSourceFunction = (source: Partial<Source>) => Promise<AxiosResponse<Response>>

// note this is only used to create nightscout sources
export function useCreateSource(): CreateSourceFunction {
  const { data: me } = useMe()
  const accountId = me?.account.id

  const { mutate } = useSources()
  const userPost = useUserPost()
  const reloadEvents = useReloadEvents()

  const createSource: CreateSourceFunction = useCallback(
    async (source) => {
      try {
        if (!accountId) {
          throw new Error("cannot create source for unknown account")
        }

        if (!userPost) {
          throw new Error("cannot create source for unauthorized user")
        }

        const url = `/api/v2/accounts/${accountId}/sources`

        const axiosResponse = await userPost<Response>({
          url,
          data: source,
        })

        void mutate()

        // schedule events to be reloaded
        setTimeout(
          () => {
            void reloadEvents()
          },
          100,
        )

        return axiosResponse
      } catch (e) {
        noticeError(
          e,
          "CreateSource",
          { source },
        )

        throw e
      }
    },
    [
      reloadEvents,
      userPost,
      accountId,
      mutate,
    ],
  )

  return createSource
}
