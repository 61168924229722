import {
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react"
import type {
  RxCollection,
  MangoQuerySortPart,
} from "rxdb"
import type {
  Loader,
  ResetLoader,
} from "./useLoadRxDBCollection"
import { useUserGet } from "src/hooks/userApi"
import { useRefValue } from "src/hooks/utils"
import { noticeError } from "src/utils"

interface Item {
  updated_at: string;
}

type Response<Key extends string, T> = {
  [k in Key]: T[];
}

interface LoadingState {
  loaded: boolean;
  loading: boolean;
}

type TransformResponse<Key extends string, T> = (data: string) => Response<Key, T>

function defaultTransformResponse<Key extends string, T>(data: string): Response<Key, T> {
  try {
    const response = JSON.parse(data) as Response<Key, T>
    return response
  } catch (e) {
    noticeError(
      e,
      "TransformResponse",
    )

    throw e
  }
}

/*
 * provides a simple loader for useLoadRxDBCollection
 */
export function useRxDBSimpleLoader<T extends Item, Key extends string>(
  responseKey: Key,
  url: string,
  transformResponse: TransformResponse<Key, T> = defaultTransformResponse<Key, T>,
): [LoadingState, Loader<T>, ResetLoader] {
  const userGet = useUserGet()
  const userGetRef = useRefValue(userGet)
  const canRequestItems = !!userGet

  const [
    loadingState,
    setLoadingState,
  ] = useState<LoadingState>({
    loaded: false,
    loading: false,
  })

  const loadingStateRef = useRef<LoadingState>(loadingState)

  // this method updates the loading state by merging an updates with the
  // current loading state
  const updateLoadingState = useCallback(
    (newLoadingState: Partial<LoadingState>) => {
      const currentLoadingState = loadingStateRef.current

      loadingStateRef.current = {
        ...currentLoadingState,
        ...newLoadingState,
      }

      setLoadingState(loadingStateRef.current)
    },
    [
      loadingStateRef,
      setLoadingState,
    ],
  )

  const resetLoader = useCallback(
    () => {
      updateLoadingState({
        loaded: false,
        loading: false,
      })
    },
    [updateLoadingState],
  )

  const loader = useMemo(
    () => (
      !canRequestItems ? undefined : (
        async (collection: RxCollection<T>) => {
          updateLoadingState({
            loading: true,
          })

          try {
            const newestItem = await collection.findOne({
              sort: [{ updated_at: "desc" as const } as MangoQuerySortPart<T>],
            })
              .exec()

            const updatedAt = (newestItem?.get("updated_at") || null) as string | null

            // if loading was canceled while awaiting the query then return
            if (!loadingStateRef.current.loading) {
              return
            }

            // if the userGet function was invalidated stop loading
            if (!userGetRef.current) {
              resetLoader()

              return
            }

            const response = await userGetRef.current<Response<Key, T>>({
              url,
              transformResponse,
              params: {
                // note: params that are null are not rendered in the url
                after: updatedAt,
              },
            })

            // if loading was canceled while awaiting the query then return
            if (!loadingStateRef.current.loading) {
              return
            }

            await collection.bulkUpsert(response.data[responseKey])
            updateLoadingState({
              loading: false,
              loaded: true,
            })
          } catch (e) {
            updateLoadingState({
              loading: false,
              loaded: true,
            })

            throw e
          }
        }
      )
    ),
    [
      userGetRef,
      canRequestItems,
      loadingStateRef,
      updateLoadingState,
    ],
  )

  return [
    loadingState,
    loader,
    resetLoader,
  ]
}
