import { useMergeRefs } from "@floating-ui/react"
import dayjs from "dayjs"
import {
  forwardRef,
  useCallback,
  useRef,
} from "react"
import type {
  MouseEventHandler,
  ComponentPropsWithoutRef,
  Ref,
} from "react"

import { GlucoseReading } from "../GlucoseReading"
import { GraphItems } from "../GraphItems"
import {
  useCellStartAndStopTime,
  useGetPixelDifferenceInMinutes,
} from "../hooks"
import styles from "./CellContent.module.scss"
import { TimeMarkers } from "./TimeMarkers"
import { useCellReadingsAndGraphItems } from "./useCellReadingsAndGraphItems"
import { useDeviceSettingsProperty } from "src/hooks"
import { useCreationOverlayPublicContext } from "src/pages/Home/CreationOverlay"

interface Props extends ComponentPropsWithoutRef<"div"> {
  index: number;
}

export const CellContent = forwardRef<HTMLDivElement, Props>(
  function CellContent(props: Props, propsRef: Ref<HTMLDivElement>) {
    const {
      index,
      ...htmlDivProps
    } = props

    const ref = useRef<HTMLDivElement>(null)
    const dotColor = useDeviceSettingsProperty("dot_color")
    const normalRangeTop = useDeviceSettingsProperty("range_normal_top")
    const normalRangeBottom = useDeviceSettingsProperty("range_normal_bottom")
    const getPixelDifferenceInMinutes = useGetPixelDifferenceInMinutes()
    const { openCreationOverlay } = useCreationOverlayPublicContext()

    const mergedRef = useMergeRefs([
      propsRef,
      ref,
    ])

    const [
      cellStartTime,
      cellStopTime,
    ] = useCellStartAndStopTime(index)

    const {
      subCells,
      glucoseReadingsProps,
      graphItemsProps,
    } = useCellReadingsAndGraphItems(
      cellStartTime,
      cellStopTime,
    )

    const onClick: MouseEventHandler = useCallback(
      (e) => {
        if (!ref.current) {
          return
        }

        const boundingClientRect = ref.current?.getBoundingClientRect()

        const diffInMinutes = getPixelDifferenceInMinutes(
          e.clientX - boundingClientRect.left,
        )

        const time = dayjs(cellStopTime)
          .add(
            diffInMinutes,
            "minute",
          )
          .toISOString()

        openCreationOverlay(time)
      },
      [
        ref,
        cellStopTime,
        getPixelDifferenceInMinutes,
        openCreationOverlay,
      ],
    )

    const onKeyPress = useCallback(
      () => {
        // this is a placeholder for accessibility
      },
      [],
    )

    return (
      <>
        <TimeMarkers
          cellStartTime={cellStartTime}
          cellStopTime={cellStopTime}
        />
        <div
          {...htmlDivProps}
          ref={mergedRef}
          onClick={onClick}
          role="button"
          tabIndex={-1}
          id="cell-spacer-graph-item"
          onKeyPress={onKeyPress}
        >
          {subCells.map(
            (
              {
                subCellStartTime,
                subCellStopTime,
                subCellStyles,
              },
              subCellIndex,
            ) => {
              const glucoseReadings = glucoseReadingsProps[subCellIndex]
              const firstAndLastGlucoseReadings = {
                first: glucoseReadings[0],
                last: glucoseReadings[glucoseReadings.length - 1],
              }

              return (
                <div
                  key={subCellStartTime}
                  className={styles.subCell}
                  style={subCellStyles}
                  id="sub-cell"
                >
                  {glucoseReadings.map(
                    (glucoseReadingProps) => (
                      <GlucoseReading
                        {...glucoseReadingProps}
                        subCellStartTime={subCellStartTime}
                        subCellStopTime={subCellStopTime}
                        id={glucoseReadingProps.key}
                        key={glucoseReadingProps.key}
                        dotColor={dotColor}
                        normalRangeTop={normalRangeTop}
                        normalRangeBottom={normalRangeBottom}
                      />
                    ),
                  )}
                  <GraphItems
                    glucoseReadings={firstAndLastGlucoseReadings}
                    graphItems={graphItemsProps[subCellIndex]}
                    subCellStartTime={subCellStartTime}
                    subCellStopTime={subCellStopTime}
                  />
                </div>
              )
            },
          )}
        </div>
      </>
    )
  },
)
