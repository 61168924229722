import {
  useMemo,
  forwardRef,
} from "react"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { useGetGlucoseValueAsPercentage } from "../hooks"
import { useDeviceSettingsProperty } from "src/hooks"

type Props = ComponentPropsWithoutRef<"div">

export const NormalRange = forwardRef<HTMLDivElement, Props>(
  function NormalRange(props: Props, propsRef: Ref<HTMLDivElement>) {
    const getGlucoseValueAsPercentage = useGetGlucoseValueAsPercentage()
    const normalRangeTop = useDeviceSettingsProperty("range_normal_top")
    const normalRangeBottom = useDeviceSettingsProperty("range_normal_bottom")

    const [normalRangeTopPercentage] = normalRangeTop === undefined
      ? [NaN]
      : getGlucoseValueAsPercentage(normalRangeTop)

    const [normalRangeBottomPercentage] = normalRangeBottom === undefined
      ? [NaN]
      : getGlucoseValueAsPercentage(normalRangeBottom)

    const normalRangeStyles = useMemo(
      () => ({
        bottom: `${normalRangeBottomPercentage}%`,
        top: `${100 - normalRangeTopPercentage}%`,
      }),
      [
        normalRangeTopPercentage,
        normalRangeBottomPercentage,
      ],
    )

    if (normalRangeBottom === undefined || normalRangeTop === undefined) {
      return null
    }

    return (
      <div
        id="normal-range-line"
        {...props}
        ref={propsRef}
        style={normalRangeStyles}
      />
    )
  },
)
