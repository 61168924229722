import {
  useEffect,
  useState,
} from "react"

export function requestLeadership(id: string, callback: (id: string) => void) {
  let resolve: () => void

  // the lock is held until this promise resolves
  const promise = new Promise<void>((res) => {
    resolve = res
  })

  // the lock request
  void navigator.locks.request(
    id,
    () => {
      callback(id)
      return promise
    },
  )

  // returns a function that releases the lock
  return () => resolve()
}

/*
 * if the web app is open in multiple tabs this hook will elect one of them to
 * be the "leader" to prevent duplication of some tasks
 */
export function useIsLeader(
  identifier: string,
  wantsLeadership = true,
): boolean {
  const [
    isLeader,
    setIsLeader,
  ] = useState<boolean>(false)

  useEffect(
    () => {
      // put in a request for leadership
      if (wantsLeadership) {
        let staleRequest = false

        const relinquishLeadershipRequest = requestLeadership(
          identifier,
          (id: string) => {
            if (id === identifier && !staleRequest) {
              setIsLeader(true)
            }
          },
        )

        // relinquish leadership request
        return () => {
          staleRequest = true
          relinquishLeadershipRequest()
          setIsLeader(false)
        }
      }
    },
    [
      wantsLeadership,
      identifier,
      setIsLeader,
    ],
  )

  return isLeader
}
