import {
  createRxDatabase,
  addRxPlugin,
} from "rxdb"
import type { RxDatabase } from "rxdb"
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode"
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder"
import { getRxStorageLoki } from "rxdb/plugins/storage-lokijs"
import { observeNewCollections } from "rxdb-hooks"
import {
  accountInsulinCollectionKey,
  accountInsulinSchema,
  accountMedicationCollectionKey,
  accountMedicationSchema,
  entryTemplateCollectionKey,
  entryTemplateSchema,
  insulinCollectionKey,
  insulinSchema,
  medicationCollectionKey,
  medicationSchema,
  userEntryCollectionKey,
  userEntrySchema,
  userEventCollectionKey,
  userEventSchema,
} from "src/schema"

// eslint-disable-next-line
const LokiIncrementalIndexedDBAdapter = require("lokijs/src/incremental-indexeddb-adapter")

//side effects
addRxPlugin(RxDBQueryBuilderPlugin)
addRxPlugin(observeNewCollections)

// in development run in dev mode
if (process.env?.NODE_ENV === "development") {
  addRxPlugin(RxDBDevModePlugin)
}

// TODO use a name that differentiates local and deployed envs
export const dbName = "sugarmate_web_app_db"

/*
 * creates a sugarmate database and its collections
 */
export async function createSugarmateRxDatabase(): Promise<RxDatabase> {
  const database = await createRxDatabase({
    name: dbName,
    storage: getRxStorageLoki({
      // eslint-disable-next-line
      adapter: new LokiIncrementalIndexedDBAdapter(),
    }),
    eventReduce: true,
    multiInstance: true,
    ignoreDuplicate: true,
  })

  await database.addCollections({
    [accountInsulinCollectionKey]: { schema: accountInsulinSchema },
    [accountMedicationCollectionKey]: { schema: accountMedicationSchema },
    [entryTemplateCollectionKey]: { schema: entryTemplateSchema },
    [insulinCollectionKey]: { schema: insulinSchema },
    [medicationCollectionKey]: { schema: medicationSchema },
    [userEntryCollectionKey]: { schema: userEntrySchema },
    [userEventCollectionKey]: { schema: userEventSchema },
  })

  return database
}
