import classNames from "classnames"
import type {
  CSSProperties,
  MouseEventHandler,
} from "react"
import {
  useCallback,
  useMemo,
} from "react"

import styles from "./GlucoseReading.module.scss"
import {
  useGlucoseValueAsPercentage,
  useTimeDifferenceInPixels,
} from "./hooks"
import { useEntryModalPublicContext } from "src/components"
import type {
  ReadOnlyUserEvent,
  ReadOnlySubEntry,
  ReadOnlyUserEntry,
  GlucoseActivity,
  SubEntry,
} from "src/types"
import { GraphDotColor } from "src/types"

export interface Props {
  glucose: GlucoseActivity;
  dotColor: GraphDotColor;
  normalRangeTop: number;
  normalRangeBottom: number;
  readingTime: string;
  subCellStartTime: string;
  subCellStopTime: string;
  item: ReadOnlyUserEvent | ReadOnlySubEntry;
  id: string;
  parentItem?: ReadOnlyUserEntry;
}

export function GlucoseReading(props: Props) {
  const {
    glucose,
    readingTime,
    subCellStopTime,
    item,
    parentItem,
    dotColor,
    normalRangeTop,
    normalRangeBottom,
  } = props

  // if element is already visible when first rendered, then animate it in
  const [
    glucosePercentage,
    glucoseClamped,
  ] = useGlucoseValueAsPercentage(
    glucose.mg_dl,
  )
  const differenceInPixels = useTimeDifferenceInPixels(
    readingTime,
    subCellStopTime,
  )
  const { openEntryModal } = useEntryModalPublicContext()

  const style: CSSProperties = useMemo(
    () => ({
      left: differenceInPixels, // TODO rtl
      bottom: `${glucosePercentage}%`,
    }),
    [
      differenceInPixels,
      glucosePercentage,
    ],
  )

  const readingClasses = useMemo(
    () => classNames(
      styles.reading,
      // note this matches the defaultts in Display/DotColor.tsx
      dotColor === GraphDotColor.ReadingBased
        ? styles.readingBased
        : styles.monochrome,
      {
        [styles.clampedReading]: glucoseClamped,
        [styles.entryReading]: !!(item as SubEntry)._id,
        [styles.high]: glucose.mg_dl > normalRangeTop,
        [styles.normal]: glucose.mg_dl <= normalRangeTop && glucose.mg_dl >= normalRangeBottom,
        [styles.low]: glucose.mg_dl < normalRangeBottom,
      },
    ),
    [
      glucose.mg_dl,
      glucoseClamped,
      item,
      normalRangeTop,
      normalRangeBottom,
      dotColor,
    ],
  )

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation()

      const subEntry = item as SubEntry
      if (parentItem && subEntry._id) {
        openEntryModal({
          userEntry: parentItem,
          subEntry: item as SubEntry,
        })
      }
    },
    [
      item,
      parentItem,
      openEntryModal,
    ],
  )

  return (
    <button
      className={styles.container}
      style={style}
      onClick={onClick}
      id="glucose-activity-icon"
    >
      <div id={"activity-graph-icon"}
        className={readingClasses}
      />
    </button>
  )
}
