import {
  useEffect,
  useState,
  useCallback,
} from "react"
import type { RxDatabase } from "rxdb"
import { useIsMounted } from "usehooks-ts"

import {
  createSugarmateRxDatabase,
  noticeError,
} from "src/utils"

export function useRxDatabase() {
  const isMounted = useIsMounted()

  const [
    database,
    setDatabase,
  ] = useState<RxDatabase | undefined>(undefined)

  // create the database and update the state
  const createDatabase = useCallback(
    async (): Promise<RxDatabase> => {
      try {
        const db = await createSugarmateRxDatabase()

        if (isMounted()) {
          setDatabase(db)
        }

        return db
      } catch (e) {
        noticeError(
          e,
          "RxDBCreateDatabase",
        )

        throw e
      }
    },
    [
      isMounted,
      setDatabase,
    ],
  )

  // call the createDatabase function on first render
  useEffect(
    () => {
      void createDatabase()
    },
    [createDatabase],
  )

  return database
}
