import type { ManipulateType } from "dayjs"
import dayjs from "dayjs"
import type { ReactNode } from "react"
import { useMemo } from "react"

import {
  useRxDBProgressiveLoader,
  useLoadRxDBCollection,
} from "src/hooks/rxdb"

import type { CollectionContext } from "src/types"

interface Item {
  updated_at: string;
}

interface Props<K extends string> {
  Context: CollectionContext;
  cacheAmount: number;
  cacheUnit: ManipulateType;
  children?: ReactNode;
  collectionKey: string;
  url: string;
  responseKey: K;
}

export function CollectionContextProvider<T extends Item, K extends string>(
  props: Props<K>,
) {
  const {
    Context,
    cacheAmount,
    cacheUnit,
    children,
    url,
    collectionKey,
    responseKey,
  } = props

  const oldestQueryTime = useMemo(
    () => dayjs().subtract(
      cacheAmount,
      cacheUnit,
    )
      .toISOString(),
    [
      cacheAmount,
      cacheUnit,
    ],
  )

  const [
    loadingState,
    loader,
    resetLoader,
  ] = useRxDBProgressiveLoader<T, K>(
    responseKey,
    url,
    oldestQueryTime,
  )

  const {
    loaded,
    loading,
    loadedUntil,
  } = loadingState

  useLoadRxDBCollection(
    collectionKey,
    loader,
    resetLoader,
  )

  const value = useMemo(
    () => ({
      loaded,
      loading,
      loadedUntil,
      collectionKey,
    }),
    [
      loaded,
      loading,
      loadedUntil,
      collectionKey,
    ],
  )

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}
