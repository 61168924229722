import { forwardRef } from "react"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"

import { usePolyglot } from "src/contexts"
import { useDexcomSource } from "src/hooks"

type Props = ComponentPropsWithoutRef<"div">

export const DexcomAttribution = forwardRef<HTMLDivElement, Props>(
  function DexcomAttribution(props: Props, propsRef: Ref<HTMLDivElement>) {
    const dexcomSource = useDexcomSource()
    const polyglot = usePolyglot()

    if (!dexcomSource) {
      return null
    }

    return (
      <div
        {...props}
        ref={propsRef}
        id="dexcom-branding"
      >
        {polyglot.t("pages.home.graph.dexcom_attribution")}
      </div>
    )
  },
)
