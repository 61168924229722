import { useCallback } from "react"
import { useReferencesContext } from "../ReferencesContext"
import { useYAxisContext } from "../YAxisContext"
import styles from "./YAxis.module.scss"
import { YAxisLabel } from "./YAxisLabel"
import { useReadableGlucoseUnit } from "src/hooks"

/*
 * the value and units labels that show the user how glucose values on the Y Axis are measured
 */
export function YAxis() {
  const {
    min,
    max,
    unitsPerMarker,
    unitMarkings,
  } = useYAxisContext()
  const glucoseUnit = useReadableGlucoseUnit()
  const { listElementRef } = useReferencesContext()

  const onClick = useCallback(
    () => {
      listElementRef.current?.scrollTo(0)
    },
    [listElementRef],
  )

  return (
    <button
      className={styles.container}
      onClick={onClick}
      id="y-axis-button"
    >
      <div className={styles.labels} >
        {unitMarkings.map((value) => {
          // the the unit marking is within 25% of the minimum value, don't label it
          if (value - min < unitsPerMarker / 4 && value !== min) {
            return null
          }

          // the the unit marking is within 25% of the maximum value, don't label it
          if (max - value < unitsPerMarker / 4 && value !== max) {
            return null
          }

          return (
            <YAxisLabel key={`graph-y-label-${value}`}
              value={value}
            />
          )
        })}
      </div>
      <div className={styles.unitsContainer}>
        <div className={styles.units}
          id="glucose-graph-units"
        >
          {glucoseUnit}
        </div>
      </div>
    </button>
  )
}
