import {
  useEffect,
  useMemo,
} from "react"
import type { RxCollection } from "rxdb"
import { useRxCollection } from "rxdb-hooks"
import { useDbContext } from "src/contexts/DbContext"
import { noticeError } from "src/utils"

export type Loader<T> = ((collection: RxCollection<T>) => Promise<void>) | undefined

export type ResetLoader = (() => void) | undefined

export function useLoadRxDBCollection<T>(
  collectionKey: string,
  loader?: Loader<T>,
  resetLoader?: () => void,
) {
  const rxCollection = useRxCollection(collectionKey) as RxCollection<T>
  const { isLoadingLeader } = useDbContext()

  /*
   * this returns falsy when:
   * - we are unable to load data (usually because the user is not signed in)
   * - the current tab or window is hidden
   * - there is another visible tab that is the leader
   * - the rx collection is not yet created
   *
   * otherwise it wraps the loader function in a try-catch
   */
  const executeLoader = useMemo(
    () => loader && isLoadingLeader && rxCollection && (
      async () => {
        try {
          await loader(rxCollection)
        } catch (e) {
          noticeError(
            e,
            "RxDBLoadCollection",
            { collectionKey },
          )
        }
      }
    ),
    [
      isLoadingLeader,
      loader,
      collectionKey,
      rxCollection,
    ],
  )

  useEffect(
    () => {
      if (executeLoader) {
        void executeLoader()
      } else {
        resetLoader?.()
        return
      }
    },
    [
      executeLoader,
      resetLoader,
    ],
  )
}
