import type { ReactNode } from "react"
import {
  useEffect,
  useMemo,
} from "react"
import { Provider as RxHooksProvider } from "rxdb-hooks"

import { DbContext } from "./DbContext"
import { useRxDatabase } from "./useRxDatabase"
import { useAuthContext } from "src/contexts/AuthContext"
import { usePageLifecycleContext } from "src/contexts/PageLifecycleContext"
import { useIsLeader } from "src/hooks/utils"
import { PageLifecycle } from "src/types"
import type { DatabaseClearerWorkerEventData } from "src/workers/databaseClearerWorker"

interface Props {
  children: ReactNode;
}

const databaseClearerWorker = new Worker(
  new URL(
    "src/workers/databaseClearerWorker/databaseClearerWorker.ts",
    import.meta.url,
  ),
)

export function DbContextProvider(props: Props) {
  const { accessToken } = useAuthContext()
  const hasAccessToken = !!accessToken

  const database = useRxDatabase()

  // call the clear database worker when there is no access token
  useEffect(
    () => {
      if (!hasAccessToken) {
        const message: DatabaseClearerWorkerEventData = {
          command: "clearUserData",
        }

        databaseClearerWorker.postMessage(message)
      }
    },
    [hasAccessToken],
  )

  useEffect(
    () => {
      const message: DatabaseClearerWorkerEventData = {
        command: "deleteStaleDatabases",
      }

      databaseClearerWorker.postMessage(message)
    },
    [],
  )

  const pageLifecycle = usePageLifecycleContext()

  // only load when this tab is visible
  const isVisible = (
    pageLifecycle === PageLifecycle.Active ||
    pageLifecycle === PageLifecycle.Passive
  )

  // only one tab can be loading data at a time and that tab must be visible
  const isLoadingLeader = useIsLeader(
    "sugarmate-load-data",
    isVisible,
  )

  const dbContextValue = useMemo(
    () => ({
      isLoadingLeader,
    }),
    [isLoadingLeader],
  )

  return (
    <RxHooksProvider db={database}>
      <DbContext.Provider value={dbContextValue}>
        {props.children}
      </DbContext.Provider>
    </RxHooksProvider>
  )
}
