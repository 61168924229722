import dayjs from "dayjs"
import { useCallback } from "react"
import { useRxCollection } from "rxdb-hooks"
import { useUserGet } from "src/hooks/userApi"
import { useRefValue } from "src/hooks/utils"
import { userEventCollectionKey } from "src/schema"
import type {
  UserEvent,
  ApiResponse,
} from "src/types"
import { noticeError } from "src/utils"

/*
 * returns a function that requests the most recent page of events and adds
 * them to the local database
 */
export function useReloadEvents() {
  const userGet = useUserGet()
  const userEventsCollection = useRxCollection(userEventCollectionKey)

  /*
   * track refs of these items so that the latest version is always used event
   * if the function has a scheduled execution via setTimeout
   */
  const userGetRef = useRefValue(userGet)
  const userEventsCollectionRef = useRefValue(userEventsCollection)

  // this function catches its errors so it will not throw
  return useCallback(
    async () => {
      try {
        if (!userGetRef.current) {
          throw new Error("cannot get events for unauthorized user")
        }

        if (!userEventsCollectionRef.current) {
          throw new Error("user events collection does not exist")
        }

        // request items for the last 24 hours
        const response = await userGetRef.current<ApiResponse<"events", Array<UserEvent>>>({
          url: "/api/v3/events",
          params: {
            after: dayjs().subtract(
              1,
              "day",
            )
              .toISOString(),
          },
        })

        // any items that already exist in the local db won't be added
        await userEventsCollectionRef.current.bulkInsert(response.data.events)
      } catch (e) {
        noticeError(
          e,
          "ReloadEvents",
          { },
        )
      }
    },
    [
      userGetRef,
      userEventsCollectionRef,
    ],
  )
}
