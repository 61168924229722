import {
  useRxDBSimpleLoader,
  useLoadRxDBCollection,
} from "src/hooks/rxdb"
import { entryTemplateCollectionKey } from "src/schema"
import type { EntryTemplate } from "src/types"

export function useLoadEntryTemplatesCollection() {
  const [
    ,
    loader,
    resetLoader,
  ] = useRxDBSimpleLoader<EntryTemplate, "entry_templates">(
    "entry_templates",
    "/api/v3/entry_templates",
  )

  useLoadRxDBCollection<EntryTemplate>(
    entryTemplateCollectionKey,
    loader,
    resetLoader,
  )
}
