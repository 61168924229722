import type { ReactNode } from "react"
import { CollectionContextProvider } from "./CollectionContextProvider"
import { UserEntriesCollectionContext } from "./UserEntriesCollectionContext"
import { userEntryCollectionKey } from "src/schema"

interface Props {
  children?: ReactNode;
}

export function UserEntriesCollectionContextProvider(props: Props) {
  return (
    <CollectionContextProvider
      Context={UserEntriesCollectionContext}
      collectionKey={userEntryCollectionKey}
      cacheAmount={90}
      cacheUnit={"days"}
      responseKey={"entries"}
      url={"/api/v3/entries"}
    >
      {props.children}
    </CollectionContextProvider>
  )
}
